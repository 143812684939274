import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { DynamicPages } from './dynamicPages'
import { RoutingMiddleware } from 'feature-router'
import { DynamicPagesSymbol } from '@wix/thunderbolt-symbols'

export const site: ContainerModuleLoader = (bind) => {
	bind(RoutingMiddleware.Dynamic, DynamicPagesSymbol).to(DynamicPages)
}

export const editor: ContainerModuleLoader = (bind) => {
	bind(RoutingMiddleware.Dynamic).to(DynamicPages)
}
